import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import bulma from 'bulma'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)
import {createPinia} from 'pinia'

const pinia = createPinia()

const Vue = createApp(App)
Vue.use(router)
Vue.use(bulma)
Vue.use(pinia)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('font-awesome-icon', FontAwesomeIcon)

fetch(process.env.BASE_URL + "config.json")
    .then((response) => response.json())
    .then((config) => {
        // either use window.config
        window.config = config
        // or use [Vue Global Config][1]
        Vue.config.globalProperties.$config = config
        Vue.mount('#app')
    })


