import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

export const userStore = defineStore('user', {
    state: () => {
        if (Cookies.get('user')) {
            return { user: Cookies.get('user') }
        } else {
            return { user: null }
        }
    },
    actions: {
        save(data) {
            this.user = data
            Cookies.set('user', this.user, {domain: '.scgrdn.pro'})
            Cookies.set('user', this.user, {domain: '.grdn.ru'})
        },
        get() {
            return Cookies.get('user')
        },
        delete() {
            Cookies.remove('user', {domain: '.scgrdn.pro'})
            Cookies.remove('user', {domain: '.grdn.ru'})
            this.user = null
        }
    },
})