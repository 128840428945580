<template>
  <div class="columns has-text-centered">
    <div class="column">
      <p class="subtitle special-title" v-if="$route.query.app_id"><font-awesome-icon icon="fa-solid fa-square-up-right" /> {{ getAppName($route.query.app_id) }}</p>
      <p class="subtitle">ЕДИНАЯ ИНФОРМАЦИОННАЯ СИСТЕМА</p>
<!--      <p class="subtitle special-subtitle" v-if="token.token && user.user">{{ userdata.get() }}</p>-->
    </div>
  </div>
</template>

<script>
import {tokenStore} from "@/store/TokenHolder";
import {userStore} from "@/store/UserHolder";
import {apiStatus} from "@/store/ApiStatus";
import {userData} from "@/store/UserData";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Hero",
  async beforeMount() {
    if (this.token.token && this.user.user) {
      await this.getUser()
    }
  },
  data() {
    return {
      user_info: null,
      token: tokenStore(),
      user: userStore(),
      status: apiStatus(),
      userdata: userData()
    }
  },
  methods: {
    getAppName: function (app_id) {
      let apps = this.$config.APPS
      let ret = null
      for (let i = 0; i < apps.length; i++) {
        for (let key in apps[i]) {
          if (apps[i][key] === app_id) {
            ret = apps[i].name
          }
        }
      }
      return ret
    },
    getUser: async function () {
      let url = `${this.$config.SSL}://${this.$config.SERVER}:${this.$config.PORT}/${this.$config.BASE}/${this.$config.API_VERSION}/GetUserInfo`
      let data = {
        "userGUID": this.user.get()
      }
      let properies = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Host": `${this.$config.SSL}://${this.$config.SERVER}:${this.$config.PORT}/`,
          "Origin": `${this.$config.SSL}://${window.location.host}/`
        }
      }
      console.log('Создаю запрос на получение пользователя...')
      await fetch(url, properies)
          .then(response => response.text())
          .then(result => {
            console.log('Доступ разрешён!\nПользователь: '+JSON.parse(result)[0]['Наименование'])
          })
    }
  }
}
</script>

<style scoped>
  .subtitle {
    opacity: 0.5;
  }
  .special-subtitle {
    font-size: 10px;
    margin-top: -25px;
  }
  .special-title {
    font-size: 10px;
    margin-bottom: -5px !important;
  }
</style>