<template>
  <div class="has-text-centered">
    <span class="my-404-text menu-label">ОШИБКА 404... Страница по адресу https://&nbsp;ЕИС&nbsp;<span class="tag is-dark menu-label">{{$route.path}}</span></span>
    <br>
    <br>
    <router-link to="/" class="button to-home is-dark">НА ГЛАВНУЮ</router-link>
  </div>
</template>

<script>
export default {
  name: "ErrorNotFound"
}
</script>

<style scoped>
.to-home {
  background-color: rgb(45, 140, 0) !important;
}
.my-404-text {
  border-bottom: #363636 1px solid;
}
</style>