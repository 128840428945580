<template>
  <SelectPlatform v-if="token" />
  <AuthForm v-else />
</template>

<script>
import { tokenStore } from "@/store/TokenHolder";
import AuthForm from "@/components/AuthForm";
import SelectPlatform from "@/components/SelectPlatform";

export default {
  name: 'HomeView',
  components: { SelectPlatform, AuthForm },
  computed: {
    token: {
      get() {
        return tokenStore().get()
      },
      set(value) {
        tokenStore().set(value)
      }
    }
  }
}
</script>
