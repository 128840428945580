import { defineStore } from 'pinia'

export const userData = defineStore('user', {
    state: () => {
        return { user: null }
    },
    actions: {
        save(data) {
            this.user = data
        },
        get() {
            return this.user
        },
        delete() {
            this.user = null
        }
    },
})