<template>
  <div class="columns">
    <div class="column is-one-fifth is-offset-two-fifths">
      <div class="field">
        <div class="control has-icons-left has-icons-right">
          <input id="login" ref="login" v-on:input="validate('login', form.login.value )" v-on:keyup="nextField" v-model="form.login.value" class="input is-dark" type="text" placeholder="Логин">
          <span class="icon is-small is-left">
          <font-awesome-icon icon="fa-solid fa-user" />
          </span>
          <span class="icon is-small is-right">
            <font-awesome-icon v-if="form.login.valid" icon="fa-solid fa-check" />
            <font-awesome-icon v-else icon="fa-solid fa-xmark" />
          </span>
        </div>
      </div>
      <div v-if="!form.login.valid" class="notification is-warning is-light">
        <strong>Подсказка</strong><br>
        <span>Логин пользователя вводится в формате "ИвановИИ"</span>
      </div>
      <div class="field" v-if="form.login.valid">
        <div class="control has-icons-left has-icons-right">
          <input id="password" ref="password" v-on:keyup="nextField" v-on:input="validate('password', form.password.value )" v-model="form.password.value" class="input is-dark" type="password" placeholder="Пароль">
          <span class="icon is-small is-left">
            <font-awesome-icon icon="fa-solid fa-key" />
          </span>
          <span class="icon is-small is-right">
            <font-awesome-icon v-if="form.password.valid" icon="fa-solid fa-check" />
            <font-awesome-icon v-else icon="fa-solid fa-xmark" />
          </span>
        </div>
      </div>
      <div v-if="!form.password.valid && form.login.valid" class="notification is-warning is-light">
        <strong>Подсказка</strong><br>
        <span>Введите пароль, используемый для входа в ServiceDesk компании ИТЦ "Гардиан"</span>
      </div>
      <button v-on:click="makeAuth" v-if="form.login.valid && form.password.valid && errors.length === 0" class="button is-dark" style="width: 100%">Войти</button>

      <div class="errors">
        <div class="notification is-danger is-light" :id="err_event(index)" v-for="(error, index) in errors" :key="error">
          <strong>{{ error.title }}</strong><br>
          <span>{{ error.description }}</span>
          <button v-on:click="del_message(index)" class="button is-danger is-inverted is-small is-fullwidth is-rounded" style="width: 100%">ЗАКРЫТЬ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tokenStore } from "@/store/TokenHolder"
import { Base64 } from 'js-base64'


export default {
  name: "AuthForm",
  mounted() {
    this.$refs.login.focus()
  },
  methods: {
    del_message: function (index) {
      let element = document.getElementById('error'+index)
      if (element) {
        element.remove()
        if (this.errors[index]) {
          this.errors.splice(index, 1)
        }
      }
    },
    err_event: function (index) {
      setTimeout(() => {
        this.del_message(index)
      }, 5000)
      return 'error'+index
    },
    nextField: function () {
      if (event.keyCode === 13) {
        switch (event.srcElement.id) {
          case 'login':
            if (this.form.login.value) {
              this.$refs.password.focus()
            } else {
              this.ehh('Ошибка заполнения', 'Поле Логин не может быть пустым!')
            }
            break;
          case 'password':
            this.makeAuth()
            break;
        }
      }
    },
    makeAuth: async function () {
      console.log('Отправляю запрос авторизации...')
      let auth_string = "Basic " + Base64.encode(this.form.login.value+':'+this.form.password.value)

      let url = `${this.$config.SSL}://${this.$config.SERVER}:${this.$config.PORT}/${this.$config.BASE}/${this.$config.API_VERSION}/auth`
      let config = {
        method: 'POST',
        withCredentials: true,
        headers: {
          "Authorization": auth_string
        }
      }

      await fetch(url, config)
          .then(response => {
            if (response.ok) {
              return response.json()
            } else {
              console.log(response)
              throw new Error('Ошибка авторизации')
            }
          })
          .then(result => {
            console.log(result)
            if (result.access) {
              this.token = result.access
            }
          }).catch(err => {
            this.ehh('Ошибка!', 'Не удалось авторизоваться. Проверьте логин или пароль!')
            console.error(err)
          })
    },
    ehh: function (title, description) {
      this.errors.push({
        title: title,
        description: description
      })
    },
    validate: function (type, value) {
      this.errors = []
      switch (type) {
        case 'login':
          this.form.login.valid = !!value;
          break;
        case 'password':
          if (value) {
            this.form.password.valid = true
          }
          break;
        default:
          return false
      }
    }
  },
  data() {
    return {
      form: {
        login: {
          valid: false,
          value: null
        },
        password: {
          valid: false,
          value: null,
          encoded: null
        }
      },
      errors: []
    }
  },
  computed: {
    token: {
      get() {
        return tokenStore().get()
      },
      set(value) {
        tokenStore().set(value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .separator {
    width: 120%;
    margin-left: -10%;
    height: 2px;
    margin-bottom: 10px;
    border-bottom: dashed 2px rgba(0,0,0,0.2);
  }
</style>