<template>
  <div class="content columns" v-if="buttons">
    <div class="column" v-for="app in buttons" :key="app">
      <div class="box my-btn my-enabled" v-if="app.enabled" v-on:click="redirect(`${$config.SSL}://${app.address}/`)">
        {{ app.name }}
      </div>
      <div v-else class="box my-btn my-disabled">
        Инструмент <span style="font-weight: 700">{{ app.name }}</span> выключен по причине <span style="font-weight: 700">{{ app.reason }}</span>!
      </div>
    </div>
  </div>
  <hr>
  <div class="columns">
    <div class="column is-one-fifth is-offset-two-fifths">
      <button class="button is-danger" v-on:click="logOut()">
        <font-awesome-icon icon="fa-solid fa-door-open" />&nbsp;Выйти
      </button>
    </div>
  </div>
</template>

<script>
import { appsList } from "@/store/AppsStore";
import { tokenStore } from "@/store/TokenHolder";

export default {
  data() {
    return {
      exit_button: 'Выйти'
    }
  },
  async beforeMount() {
    await this.get_buttons()
    this.check_redirect()
  },
  computed: {
    token: {
      get() {
        return tokenStore().get()
      },
      set(value) {
        tokenStore().set(value)
      }
    },
    buttons: {
      get() {
        return appsList().get()
      },
      set(value) {
        appsList().set(value)
      }
    }
  },
  methods: {
    get_buttons: async function () {
      console.log('Отправляю запрос получения кнопок...')

      let url = `${this.$config.SSL}://${this.$config.SERVER}:${this.$config.PORT}/${this.$config.BASE}/${this.$config.API_VERSION}/EIS_GetButtons`
      let config = {
        method: 'POST',
        withCredentials: true,
        headers: {
          "Authorization": "Bearer " + this.token
        }
      }

      await fetch(url, config)
          .then(response => response.ok ? response.text() : null)
          .then(result => {
            this.buttons = JSON.parse(result)
          }).catch(() => {
            console.log('Ошибка получения кнопок...')
          })
    },
    check_redirect: function () {
      let toRedirect = this.$route.query.app_id
      let apps = this.buttons

      if (toRedirect) {
        console.log('Обнаружен редирект')

        for(let i = 0; i < apps.length; i++) {
          for(let key in apps[i]) {
            if (apps[i][key] === toRedirect) {
              console.log('Перенаправляю на: ' + apps[i].address)
              window.location.replace(`${this.$config.SSL}://${apps[i].address}/`)
            }
          }
        }
      }
    },
    redirect: function (url) {
      window.location.replace(url);
    },
    logOut: function () {
      this.token = null
    }
  }
}
</script>

<style scoped>
  .my-btn {
    height: 100%;
    text-align: center;
  }
  .my-disabled {
    background-color: #ffffff !important;
    background: repeating-linear-gradient(-60deg, #f1f1f1 0, #f1f1f1 1px, transparent 1px, transparent 5px);
    color: #d3d3d3 !important;
    cursor: not-allowed;
  }
  .my-enabled {
    background-color: #ffffff !important;
    cursor: pointer;
    scale: 1;
    transition: 0.3s;
  }
  .my-enabled:hover {
    background-color: rgb(45, 140, 0) !important;
    scale: 1.05;
    color: #ffffff !important;
  }
  .is-danger {
    width: 100%;
    background-color: rgb(140, 25, 0) !important;
    font-weight: 200;
    font-size: 16px;
    transition: 0.4s;
  }
  .is-danger:hover {
    font-weight: 900;
    color: black !important;
    background-color: rgb(238, 124, 91) !important;
  }
</style>