import { defineStore } from 'pinia'

export const apiStatus = defineStore('status', {
    state: () => {
        return { status: 'loading' }
    },
    actions: {
        accept() {
            this.status = true
        },
        deny() {
            this.status = false
        },
        get() {
            return this.status
        }
    },
})