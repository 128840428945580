import { defineStore } from 'pinia'

export const appsList = defineStore('apps_store', {
    state: () => {
        return { apps_store: null }
    },
    actions: {
        set(v) {
            this.apps_store = v
        },
        get() {
            return this.apps_store
        }
    },
})